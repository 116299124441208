<template>
	<div>
		<page-authorization-container :page="page">
			<h2 class="mb-4">Calibration data</h2>

			<div v-if="$parent.scenario.status.isRunning">
				<page-running />
			</div>
			<div v-else-if="data.calibrationOptions.length < 1">
				<p v-if="isNullOrEmpty(data.pageText)">
					There is no calibration data available for this watershed.
					Visit the <router-link to="/help">documentation and support</router-link> page to see which watersheds have been calibrated for {{siteText.siteName}}.
				</p>
				<div v-else v-html="data.pageText"></div>
			</div>
			<div v-else>
				<div v-html="data.pageText"></div>
				<p>
					{{siteText.siteName}} uses the following calibrations for this watershed by default. Toggle the input below to run your model with or without
					these calibrations. Please note that any customizations you make to these inputs will override the calibrations.
					Visit the <router-link to="/help">documentation and support</router-link> page for more information
					about the calibrated data.
				</p>

				<error-list :errors="page.saveErrors"></error-list>
				<success-alert ref="savedAlert" text="Changes saved." />

				<b-form @submit.prevent="save">
					<b-form-group class="bg-light py-3 px-2 mb-4 border">
						<b-form-select v-model="data.customCalibrationID" :options="data.calibrationOptions" @change="refreshMap">
							<template #first>
								<b-form-select-option :value="null">Do not use any calibration data for this scenario</b-form-select-option>
							</template>
						</b-form-select>
					</b-form-group>

					<page-loading :loading="loadingMap"></page-loading>
					<div v-if="selectedCalibration !== null && !loadingMap">
						<h4 class="mt-4 mb-2">Calibration data for {{ selectedCalibration.name }}</h4>

						<project-map :project-map="selectedProjectMap" height="300px" show-layer-control show-extra-layers></project-map>

						<p class="mt-2">
							V = Value used<br />
							A = Amount to add to baseline value<br />
							% = Percentage of baseline, i.e. (1 + x) * (baseline value)
						</p>

						<b-table-simple responsive small>
							<b-thead>
								<b-tr>
									<b-th>Table/Field</b-th>
									<b-th>Value</b-th>
									<b-th>Type</b-th>
								</b-tr>
							</b-thead>
							<b-tbody v-for="(tbl, i) in Object.keys(selectedCalibration.mods)" :key="i">
								<b-tr class="bg-light">
									<b-td colspan="3"><strong>{{tbl.toUpperCase()}}</strong></b-td>
								</b-tr>
								<b-tr v-for="(fld, j) in Object.keys(selectedCalibration.mods[tbl])" :key="j">
									<b-td><span class="px-3"></span>{{ fld }}{{ getIndexConditions(fld) }} {{ getLanduseConditions(fld) }}</b-td>
									<b-td>{{ selectedCalibration.mods[tbl][fld] | number(3) }}</b-td>
									<b-td>{{ getType(fld) }}</b-td>
								</b-tr>
							</b-tbody>
						</b-table-simple>

						<p><em>After running your scenario, calibration information can be found in the scenario zip file under Watershed/text/calibration_info.txt</em></p>
					</div>

					<fixed-action-bar>
						<save-button :saving="page.saving" class="ml-2 mr-2" />
						<back-button class="btn btn-secondary mr-2" />
					</fixed-action-bar>
				</b-form>
			</div>
		</page-authorization-container>
	</div>
</template>

<script>
	import ProjectMap from '@/components/ProjectMap';

	export default {
		name: 'EditCalibration',
		components: {
			ProjectMap
		},
		data() {
			return {
				projectID: this.$route.params.id,
				scenarioID: this.$route.params.scenarioID,
				page: {
					errors: [],
					loading: false,
					showLogin: false,
					saveErrors: [],
					saving: false
				},
				data: {
					ignoreCalibration: false,
					customCalibrationID: null,
					calibrationOptions: [],
					calibrationData: {},
					pageText: null
				},
				projectMap: {
					watershedBounds: [],
					connections: [],
					geoJsonUrls: {},
					extraLayers: []
				},
				loadingMap: false
			}
		},
		async created() {
			await this.get();
		},
		computed: {
			useCalibration: {
				get() {
					return !this.data.ignoreCalibration;
				},
				set(newValue) {
					this.data.ignoreCalibration = !newValue;
				}
			},
			selectedCalibration() {
				if (this.data.customCalibrationID === null) return null;
				if (this.data.customCalibrationID in this.data.calibrationData)
					return this.data.calibrationData[this.data.customCalibrationID];
				return null;
			},
			selectedProjectMap() {
				let map = this.projectMap;
				map.extraLayers = [];
				if (this.selectedCalibration !== null) {
					if (this.selectedCalibration.watersheds !== null && this.selectedCalibration.watersheds.geojson.features.length > 0)
						map.extraLayers.push(this.selectedCalibration.watersheds);
					if (this.selectedCalibration.locationPoints.geojson.features.length > 0)
						map.extraLayers.push(this.selectedCalibration.locationPoints);
				}

				return map;
			}
		},
		methods: {
			async get() {
				this.page.errors = [];
				this.page.loading = true;

				try {
					const response = await this.$http.get(`scenarios/general/calibration/${this.scenarioID}`, this.getTokenHeader());
					this.log(response.data);
					this.data = response.data;

					const response2 = await this.$http.get(`projects/map/${this.projectID}`, this.getTokenHeader());
					this.projectMap = response2.data;
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.errors = this.logError(error);
				}

				this.page.loading = false;
			},
			async save() {
				this.page.saveErrors = [];
				this.page.saving = true;
				try {
					var data = {
						ignoreCalibration: this.data.customCalibrationID === null,
						customCalibrationID: this.data.customCalibrationID
					};
						
					const response = await this.$http.post(`scenarios/general/calibration/${this.scenarioID}`, data, this.getTokenHeader());
					this.log(response.data);
					await this.get();
					this.$refs.savedAlert.startAlert();
				} catch (error) {
					if (this.isApiUnauthorized(error)) this.page.showLogin = true;
					else this.page.saveErrors = this.logError(error);
				}

				this.page.saving = false;
			},
			getType(fld) {
				if (this.selectedCalibration == null) return '';
				else {
					if (this.selectedCalibration.addColumns != null && this.selectedCalibration.addColumns.includes(fld)) return 'A';
					else if (this.selectedCalibration.percentColumns != null && this.selectedCalibration.percentColumns.includes(fld)) return '%';
					else return 'V';
				}
			},
			getLanduseConditions(fld) {
				if (this.selectedCalibration == null) return '';
				else if (this.selectedCalibration.landuseConditions != null && (fld in this.selectedCalibration.landuseConditions)) {
					return `(${this.selectedCalibration.landuseConditions[fld].join(', ')})`;
				}
				return '';
			},
			getIndexConditions(fld) {
				if (this.selectedCalibration == null) return '';
				else if (this.selectedCalibration.indexConditions != null && (fld in this.selectedCalibration.indexConditions)) {
					return `(${this.selectedCalibration.indexConditions[fld].join('-')})`;
				}
				return '';
			},
			async refreshMap() {
				this.loadingMap = true;
				await this.sleep(500);
				this.loadingMap = false;
			},
			sleep(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			}
		}
	}
</script>
